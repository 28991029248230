<template>
     <div class="messDiv  text-center">
  <h1>Создайте свою анкету</h1>
  <p>Заполняйте и указывайте свои настоящие данные. Позже вы сможете выбрать, какие данные о вас необходимо скрыть</p>
  <button type="button" @click="loadCreatePerson" class="btn btn-lg btnBg">Начать 🍑</button>
</div>
  </template>
  
  <script>
  import { mapActions, mapState } from 'vuex';
  export default {
    name: 'messReg',
    computed: {
      ...mapState(['currentComponent'])
    },
    methods: {
      ...mapActions(['changeComponent']),
      loadCreatePerson() {
        this.changeComponent('createPerson');
      }
    }
  };
  </script>
  
  <style scoped>
    .messDiv{
      display: flex;
      flex-direction: column; /* Элементы друг под другом */
      justify-content: center; /* Вертикальное выравнивание */
      align-items: center; /* Горизонтальное выравнивание по центру */
      height: 100vh; /* Высота на весь экран */
      text-align: center;
      color: #fff;          
      
    }
    .messDiv p {
      padding: 20px;
    }
    .messDiv button {
      color: #feb47b;
      width: 80%;
      background-color:aliceblue;
    }
  </style>
  