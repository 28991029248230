<template>
    <div class="questions-display mt-4">
      <h5>Ответьте на вопросы:</h5>
      <!-- Используем v-model для привязки данных -->
      <textarea class="form-control" rows="6" :value="formattedQuestions" readonly></textarea>
    </div>
  </template>
  
  <script>
  export default {
    props: ['questions'],
    computed: {
      formattedQuestions() {
        return this.questions.join('\n'); // Объединяем вопросы в одну строку с переносами
      }
    }
  }
  </script>