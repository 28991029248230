<!-- GenderSelector.vue -->
<template>
    <div class="d-flex gap-3">
      <button 
        @click="selectGender('man')" 
        :class="['btn', selectedGender === 'man' ? 'bt-tap' : 'bt-select']"
      >
        Я мужчина
      </button>
      <button 
        @click="selectGender('woman')" 
        :class="['btn', selectedGender === 'woman' ? 'bt-tap' : 'bt-select']"
      >
        Я женщина
      </button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        selectedGender: null  // хранит выбранный пол ('man' или 'woman')
      };
    },
    methods: {
      selectGender(gender) {
        this.selectedGender = gender;  // обновляем выбранный пол
        this.$emit('genderSelected', gender);  // отправляем событие с выбранным полом
      }
    }
  };
  </script>
  
  <style scoped>
  .d-flex {
    display: flex;
    width: 80%;
  }
  .d-flex button{
    color: #9d9d9d !important;
      width: 80%;
      background-color:aliceblue !important;
      margin-top: 15px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  .bt-tap {
    color:#fff !important;
    background-color: #feb47b !important;
  }
  .bt-select {
    color:#feb47b !important;
    background-color: #ffffff !important;
  }

  </style>