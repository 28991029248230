<template>
  <div>
    <!-- Контейнер с прокруткой -->
    <h5>Выбранные интересы:</h5>
    <div class="interests-scroll-container">
      <button
        v-for="interest in filteredInterests"
        :key="interest"
        :class="['btn', 'bt-select', 'interest-button', { 'active': selectedInterests.includes(interest) }]"
        @click="toggleInterest(interest)"
      >
        {{ interest }}
      </button>
    </div>

    <!-- Отображение выбранных интересов -->
   
  </div>
</template>

<script>
export default {
  props: ['category'],
  data() {
    return {
      allInterests: {
        Общение: ["Книги и литература", "Кино и сериалы", "Музыка", "Видеоигры", "Путешествия", "Кулинария", "Спорт", "Искусство", "Фотография", "Театр и мюзиклы", "Технологии и гаджеты", "Наука и космос", "Психология и саморазвитие", "Философия", "Мода и стиль", "Животные", "Настольные игры", "Образование и учеба", "Автомобили и мотоциклы", "Фитнес и здоровье", "История", "Хобби и ремесла", "Комиксы и аниме", "Волонтерство и социальные проекты"],
        Близость: ["Чувственность и романтика", "Эмоциональная связь", "Эксперименты и новизна", "Нежные разговоры", "Ароматы и атмосфера", "Тактильные ощущения", "Музыка и ритм", "Игровые моменты", "Взаимная инициатива", "Доверие и открытость", "Поцелуи", "Темп и ритм", "Общение о желаниях", "Приключения вне дома", "Игры с ощущениями", "Границы и их уважение", "Одежда и детали", "Юмор и лёгкость", "Энергетика и страсть", "Долгие прелюдии", "Объятия и физическое тепло", "Глаза и зрительный контакт", "Спонтанность", "Сюрпризы и подарки", "Особое внимание к деталям"],
        Отношения: ['Спорт', 'Творчество', 'Саморазвитие', 'Психология', 'Семейные ценности', 'Юмор', 'Поддержка'],
      },
      selectedInterests: [],
    };
  },
  computed: {
    filteredInterests() {
      return this.allInterests[this.category] || [];
    },
  },
  methods: {
    toggleInterest(interest) {
      if (this.selectedInterests.includes(interest)) {
        this.selectedInterests = this.selectedInterests.filter((i) => i !== interest);
      } else {
        this.selectedInterests.push(interest);
      }
    },
  },
};
</script>

<style scoped>
/* Контейнер с прокруткой */
.interests-scroll-container {
  position: fixed; /* Фиксированное позиционирование */
  top: 15%; /* Расположение сверху */
  left: 0;
  right: 0;
  height: 40vh; /* Высота равна половине высоты экрана */
  overflow-y: auto; /* Прокрутка, если кнопок слишком много */
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-content: flex-start; /* Кнопки выравниваются по началу контейнера */
  padding: 10px;

}

/* Стили для кнопок интересов */
.interest-button {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  white-space: nowrap;
}

.bt-select {
  color: #feb47b !important;
  background-color: #ffffff !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.bt-select.active {
  color: #fff !important;
  background-color: #feb47b !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
</style>