<template>
  <div class="date-picker">
    <!-- Выпадающий список для дня -->
    <div class="dropdown" @click="toggleDropdown('day')">
      <span>{{ selectedDay || 'День' }}</span>
      <ul v-if="isOpen.day" class="dropdown-list">
        <li v-for="day in days" :key="day" @click="selectDay(day)">{{ day }}</li>
      </ul>
    </div>

    <!-- Выпадающий список для месяца -->
    <div class="dropdown" @click="toggleDropdown('month')">
      <span>{{ selectedMonth ? months[selectedMonth - 1] : 'Месяц' }}</span>
      <ul v-if="isOpen.month" class="dropdown-list">
        <li v-for="(month, index) in months" :key="index" @click="selectMonth(index + 1)">
          {{ month }}
        </li>
      </ul>
    </div>

    <!-- Выпадающий список для года -->
    <div class="dropdown" @click="toggleDropdown('year')">
      <span>{{ selectedYear || 'Год' }}</span>
      <ul v-if="isOpen.year" class="dropdown-list">
        <li v-for="year in years" :key="year" @click="selectYear(year)">{{ year }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  data() {
    const currentYear = new Date().getFullYear();
    return {
      selectedDay: "",
      selectedMonth: "",
      selectedYear: "",
      isOpen: { day: false, month: false, year: false },
      days: Array.from({ length: 31 }, (_, i) => i + 1),
      months: [
        "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
      ],
      years: Array.from({ length: 35 }, (_, i) => currentYear - 16 - i), // Диапазон от 16 до 50 лет
    };
  },
  methods: {
    toggleDropdown(type) {
      this.isOpen = { day: false, month: false, year: false };
      this.isOpen[type] = true;
    },
    selectDay(day) {
      this.selectedDay = day;
      this.isOpen.day = false;
      this.emitDate();
    },
    selectMonth(month) {
      this.selectedMonth = month;
      this.isOpen.month = false;
      this.updateDaysInMonth();
      this.emitDate();
    },
    selectYear(year) {
      this.selectedYear = year;
      this.isOpen.year = false;
      this.updateDaysInMonth();
      this.emitDate();
    },
    emitDate() {
      if (this.selectedDay && this.selectedMonth && this.selectedYear) {
        const date = `${this.selectedYear}-${String(this.selectedMonth).padStart(2, "0")}-${String(this.selectedDay).padStart(2, "0")}`;
        this.$emit("date-selected", date);
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = { day: false, month: false, year: false };
      }
    },
    updateDaysInMonth() {
      // Получаем максимальное количество дней в выбранном месяце и году
      const maxDays = this.getDaysInMonth(this.selectedYear, this.selectedMonth);
      // Если выбранный день больше, чем максимальное количество дней в месяце, сбрасываем день
      if (this.selectedDay > maxDays) {
        this.selectedDay = "";
      }
      // Обновляем список дней в соответствии с количеством дней в месяце
      this.days = Array.from({ length: maxDays }, (_, i) => i + 1);
    },
    getDaysInMonth(year, month) {
      // Возвращаем количество дней в месяце, учитывая високосные годы для февраля
      if (!year || !month) return 31; // по умолчанию
      return new Date(year, month, 0).getDate(); // Получает последний день выбранного месяца
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>


<style scoped>
.date-picker {
  display: flex;
  gap: 15px; /* Добавим немного пространства между колонками */
  width: 80%; /* Общая ширина компонента */
}

.dropdown {
  position: relative;
  cursor: pointer;
  padding: 8px;
  border: 1px solid #ffffff;
  background-color: aliceblue;
  color: #9d9d9d;
  border-radius: 4px;
  width: 100px; /* Устанавливаем ширину каждой колонки в 100px */
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-list {
  position: absolute;
  top: 1%;
  left: -2px;
  right: 0;
  max-height: 150px; /* Ограничение высоты */
  width: 98px;
  overflow-y: auto;
  overflow-x: hidden; /* Отключаем горизонтальную прокрутку */
  border: 1px solid #ffffff;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-list li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #dedede;
}
</style>