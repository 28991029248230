<template>
  <div class="container">
  <div class="messDiv  text-center">
      <h1 v-if="user.name">{{ `${user.name}, ${age}`}}</h1>
      <h1 v-else >{{ 'Ваше имя' }}</h1>
      <h3>{{ zodiacSign || '' }}</h3>
      <p></p>
      <input 
        v-model="user.name" 
        type="text" 
        class="form-control" 
        placeholder="Укажите ваше имя" 
        required
        @focus="onFocus"
        @blur="onBlur" 
        @input="validateName"
        title="Только буквы и пробелы"
      />
      <DateWheelPicker @date-selected="handleDateSelected" />
      <GenderSelector @genderSelected="updateGender" />
      <LookingForSelector @lookingForSelected="updateLookingFor" />
      <button type="button" @click="loadCreatePerson" class="btn btn-lg btnBg">Дальше</button>
  </div>
</div>
</template>
  <script>
import { mapActions, mapState } from 'vuex';
import DateWheelPicker from './adders/DateWheelPicker.vue'; // путь к вашему компоненту
import GenderSelector from './adders/GenderSelector.vue';
import LookingForSelector from './adders/LookingForSelector.vue';

  export default {
    name: 'messReg',
    components: {
      DateWheelPicker,
      GenderSelector,
      LookingForSelector,
    },
    data() {
    return {
      selectedDate: null, // Храним выбранную дату
      zodiacSign: '',
      age: '',
      selectedGender: null,
      selectedLookingFor: null,
      initialHeight: window.innerHeight, // Начальная высота экрана
      keyboardOpen: false,
    };
  },
    computed: {
      ...mapState(['user','currentComponent']),
      selectedGenderText() {
        return this.selectedGender === 'man' ? 'Мужчина' : this.selectedGender === 'woman' ? 'Женщина' : 'не выбран';
      },
      selectedLookingForText() {
        return this.selectedLookingFor === 'men' ? 'Мужчин' : this.selectedLookingFor === 'women' ? 'Девушек' : 'не выбрано';
      },
    },
    methods: {
      ...mapActions(['updateUserData', 'changeComponent']),
      loadCreatePerson() {
        this.changeComponent('createPerson');
      },
      validateName() {
        //this.user.name = this.user.name.replace(/[^A-Za-zА-Яа-яЁё\s]/g, '');
      },
      onFocus() {
        this.keyboardOpen = true;
        document.body.style.overflow = "clip"; // Блокируем прокрутку при открытой клавиатуре
      },
      onBlur() {
        this.keyboardOpen = false;
        document.body.style.overflow = "clip"; // Восстанавливаем прокрутку при закрытии клавиатуры
        window.scrollTo(0, 0); // Сбрасываем прокрутку вверх
      },
      handleResize() {
        const newHeight = window.innerHeight;
        if (this.keyboardOpen && newHeight > this.initialHeight * 0.9) {
          // Если высота экрана вернулась к исходной, предполагаем, что клавиатура закрылась
          this.keyboardOpen = false;
          //document.body.style.width = "100%";
          document.body.style.overflow = "clip"; // Восстанавливаем прокрутку
          window.scrollTo(0, 0); // Сбрасываем прокрутку вверх
        }
      },
      handleDateSelected(date) {
      // Получаем дату от компонента DatePicker и сохраняем её
      this.selectedDate = date;
      this.zodiacSign = this.calculateZodiacSign(date);
      this.age = this.calculateAge(date);
      },
      calculateZodiacSign(date) {
        const parts = date.split('-').map(Number);
        const month = parts[1];
        const day = parts[2];

      if ((month === 3 && day >= 21) || (month === 4 && day <= 19)) {
        return "Овен";
      } else if ((month === 4 && day >= 20) || (month === 5 && day <= 20)) {
        return "Телец";
      } else if ((month === 5 && day >= 21) || (month === 6 && day <= 20)) {
        return "Близнецы";
      } else if ((month === 6 && day >= 21) || (month === 7 && day <= 22)) {
        return "Рак";
      } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
        return "Лев";
      } else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
        return "Дева";
      } else if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) {
        return "Весы";
      } else if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) {
        return "Скорпион";
      } else if ((month === 11 && day >= 22) || (month === 12 && day <= 21)) {
        return "Стрелец";
      } else if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) {
        return "Козерог";
      } else if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
        return "Водолей";
      } else if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
        return "Рыбы";
      }

      return null; // На случай, если дата не подходит под ни один знак
      },
      calculateAge(date) {
      const birthDate = new Date(date);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      
      // Проверка, был ли день рождения уже в этом году
      const hasHadBirthdayThisYear = 
        today.getMonth() > birthDate.getMonth() || 
        (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

      // Если день рождения ещё не был, вычитаем один год
      if (!hasHadBirthdayThisYear) {
        age -= 1;
      }

      return age;
      },
      updateGender(gender) {
        this.selectedGender = gender;
      },
      updateLookingFor(option) {
        this.selectedLookingFor = option;
      }
      
    }
  };
  </script>
  
  <style scoped>
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100vh; /* Полная высота экрана */
    }
    .messDiv{
      display: flex;
      flex-direction: column; /* Элементы друг под другом */
      justify-content: center; /* Вертикальное выравнивание */
      align-items: center; /* Горизонтальное выравнивание по центру */
      width: 100%;
      height: 100vh; /* Высота на весь экран */
      text-align: center;
      color: #fff;
             
    }
    .messDiv p {
      padding: 10px;
    }
    .messDiv h1,h2,h3,h4{
      padding: 0;
      margin: 0;
    }
    .messDiv button {
      color: #feb47b;
      width: 80%;
      background-color:aliceblue;
      margin-top: 35px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
    .messDiv input, select {
      color: #feb47b;
      background-color: aliceblue;
      border-color: aliceblue;
      text-align: center;
      margin-bottom: 15px; /* Отступ снизу от поля */
      width: 80%;
      padding: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

  </style>
  