<template>
     <main class="container mb-9 ">
      <div class="">
        <div class="peach-background">
        <div class="profile-header" >
          <img
               :src="'/api/users/f1/'+user.avatar || 'noAvatar.jpg'"
                alt="Avatar"
                style="cursor: pointer;"
          >          
          <br>
          <br>
          <h2>{{ user.name }}, {{ user.birthdate }}</h2>
          <div class="profile-info">
            <p>{{ user.about }}</p>
          </div>
          <div class="message">
            <p>Редактирование анкет временно недоступно. Приложение находится в разработке</p>
          </div>
        </div>
        </div>
      </div>
      <div class="container text-center p-3">
    <!-- Кнопка Lovina Premium -->
    <div class="card mb-3 premium-card text-dark shadow-lg p-3 mb-5 bg-light rounded">
      <div class="card-body">
        <h5 class="card-title">
          <i class="fas fa-heart"></i> Верификация аккаунта
        </h5>
        <p class="card-text">расширенные возможности для знакомств и общения</p>
      </div>
    </div>
  </div>
      <br>
    </main>
  </template>
  <script>
 import { mapState } from 'vuex';
  export default {
  name: 'editPerson',
  data() {
    return {
    };
  },
  methods: {
 
  },
  computed: {
    ...mapState(['user'])
  },
  created() {

  },
  mounted(){
  
  }
};
  </script>
  
  <style scoped>
  /* Стили для компонента Home */
  .profile-header {
      text-align: center;
      margin: 20px 0;
      color: #000;
    }
    .profile-header img {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      object-fit: cover;
      color: #000;
    }
    .profile-info {
      text-align: center;
      margin: 5px 0px;
      color:#000;
    }
    .message {
      text-align: center;
      margin: 5px 0px;
      color:#8c8c8c;
    }
    .bgGb{
      background-color: #ff7e5f;
    }
    .peach-background {
  background: #fff;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.2); /* Внутренняя и внешняя тень */
  padding: 40px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  height: 400px;
  width: 100%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

  </style>
  