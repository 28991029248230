// main.js
import { createApp } from 'vue';
import App from './App.vue';
import store from './store'; // Импорт хранилища Vuex
import Vue3TouchEvents from 'vue3-touch-events';

const app = createApp(App);

// Подключаем Vuex хранилище к приложению Vue
app.use(store);
app.use(Vue3TouchEvents)
app.mount('#app');
