<template>
  <div class="category-selector">
    <!-- Если категория выбрана -->
    <button
      v-if="selectedCategory"
      class="btn bt-select move-to-top position-absolute top-0 start-50 translate-middle-x"
      @click="resetCategory"
    >
      {{ selectedCategory }} ↓
    </button>

    <!-- Если категория не выбрана -->
    <div v-else class="buttons-container">
      <button
        v-for="cat in categories"
        :key="cat"
        class="btn bt-select category-button fade-in"
        @click="selectCategory(cat)"
      >
        {{ cat }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['selectedCategory'],
  data() {
    return {
      categories: ['Общение', 'Близость', 'Отношения'],
    };
  },
  methods: {
    selectCategory(category) {
      this.$emit('category-selected', category);
    },
    resetCategory() {
      this.$emit('reset-category');
    },
  },
};
</script>

<style scoped>
.category-selector{
  width: 80%;
}
/* Общий стиль кнопок */
.category-button {
  width: 100%;
  margin: 15px auto;
  display: block;
  position: relative;
  transition: all 0.6s ease-in-out;
}

/* Анимация появления */
.fade-in {
  opacity: 0; /* Исходное состояние (скрыто) */
  transform: translateY(20px); /* Немного смещено вниз */
  animation: fadeIn 0.6s ease forwards; /* Анимация появления */
}

/* Анимация */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Начальная позиция */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Конечная позиция */
  }
}

/* Положение после выбора категории */
.move-to-top {
  width: 80%;
  animation: fadeIn 0.6s ease forwards;
  margin-top: 20px;
}

.bt-tap {
    color:#fff !important;
    background-color: #feb47b !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  .bt-select {
    color:#feb47b !important;
    background-color: #ffffff !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
</style>