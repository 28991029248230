<template>
<div class="container my-4">
    <div class="overflow-auto" style="max-height: 100%;">

        <div class="btn-group toggle-buttons" role="group">
                    <button type="button" class="btn btn-left active" @click="btShowMatch">Взаимные</button>
                    <button type="button" class="btn btn-right" @click="btShowLike">Вас лайкнули</button>
        </div>

        <div v-if="endAnkets" class="row g-0 h-100 text-light text-center" style="padding-top: 100px;">
                <h1>У вас еще нет взаимных лайков</h1>
        </div>
        <div class="loading position-absolute top-50 start-50 translate-middle-x" v-if="loading">
            <div class="gradient-div d-flex justify-content-center">
                <p class="position-absolute top-0 start-50 translate-middle-x"></p>
                <div class="spinner-border text-light " role="status"></div>
                <p class="position-absolute text-center text-light pt-4 mt-4">Загрузка взаимностей</p>
            </div>
        </div>
        <div v-if="showMatch">
            <div  v-for="(card, index) in match"  :key="index" class="card mx-auto shadow bg-white text-dark h-100 m-4" style="max-width: 540px;" >
                <div class="row g-0 h-100">
                    <div class="col-4">
                        <img :src="`./api/users/f1/${card.avatar}`" class="img-fluid rounded-start h-100">
                    </div>
                    <div class="col-8 h-100">
                        <div class="card-body">
                            <div>
                                <h5 class="card-title">{{ card.name }}, {{ calculateAge(card.birthdate) }}</h5>
                                <p class="card-text">{{ card.about }}</p>
                            </div>
                            <!-- Кнопка снизу -->
                            <a class="btn btn-dark w-100 mt-4" @click="getPeachUser(card.tid,card.name)">
                                <i class="bi bi-chat-dots"></i> Написать
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showLike">
            <div  v-for="(card, index) in myLike"  :key="index" class="card mx-auto shadow bg-white text-dark h-100 m-4" style="max-width: 540px;" >
                <div class="row g-0 h-100">
                    <div class="col-4">
                        <img :src="`./api/users/f1/${card.avatar}`" class="img-fluid rounded-start h-100">
                    </div>
                    <div class="col-8 h-100">
                        <div class="card-body">
                            <div>
                                <h5 class="card-title">{{ card.name }}, {{ calculateAge(card.birthdate) }}</h5>
                                <p class="card-text">{{ card.about }}</p>
                            </div>
                            <!-- Кнопка снизу -->
                            <a class="btn btn-dark w-100 mt-4" @click="getRefresh(card.tid,card.name)">
                                <i class="bi bi-check2"></i> Подтвердить и написать
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>
      
<script>
import { mapActions, mapState } from 'vuex';
import { fetchAnkets } from '../utils/api';
export default {
name: 'matchAnkets',
data() {
    return {
        loading:true,
        endAnkets:false,
        match:[],
        myLike:[],
        showMatch:false,
        showLike:false,
    };
},
computed: {
    ...mapState(['user', 'currentComponent']),
    },
    methods: {
        ...mapActions(['changeComponent','updateMenuData']),
        async getFetchMatch(){
            const token = this.user.token
            const anketsUser = await fetchAnkets('/match',{token})
            if(anketsUser.data){
                //отображение анкет
                this.match.push(...anketsUser.data)
                this.myLike.push(...anketsUser.myLike)
                this.loading = false
                this.endAnkets = false
                this.showMatch = true
            }else{
                if(anketsUser.myLike){
                    this.myLike.push(...anketsUser.myLike)
                }
                this.loading = false
                this.endAnkets = true
            }
        },
        async getRefresh(ttid,ancetName){
            const data = {
                ...this.user,
                ttid,
                ancetName
            }
            await fetchAnkets('/getPeachUser',{data})
        },
        
        async getPeachUser(ttid,ancetName){
            const data = {
                ...this.user,
                ttid,
                ancetName
            }
            await fetchAnkets('/getPeachUser',{data})
        },
        calculateAge(birthdate) {
            const today = new Date();
            const birthDate = new Date(birthdate);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        async btShowMatch(){
            //await this.getFetchMatch()
            if(this.match.length === 0){
                this.endAnkets = true
                this.showLike = false
                this.showMatch = false
            }else{
                this.endAnkets = false
                this.showLike = false
                this.showMatch = true
            }
        },
        btShowLike(){
            this.endAnkets = false
            this.showMatch = false
            this.showLike = true
        }
    },
    mounted() {
      this.updateMenuData(true)
      this.getFetchMatch()
    },
}
</script>
      
<style scoped>
.card {
    max-height: 300px; /* Задаем максимальную высоту карточки */
}
img {
    object-fit: cover; /* Сохраняет пропорции изображения, заполняя контейнер */
    height: 100%;      /* Занимает 100% высоты родителя */
    width: 100%;       /* Занимает 100% ширины родителя */
}

.toggle-buttons {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 15px;
    overflow: hidden;
    border-color: #fff;
    box-shadow: inset -20px 14px 50px rgba(0, 0, 0, .1), 0px 3px 3px rgba(0, 0, 0, .2);

}

.btn-left,
.btn-right {
    flex: 1;
    padding: 10px;
    border: none;
    font-size: 16px;
    transition: background-color 0.3s;
    background-color: #fff;
    color: #000;
}

.btn-left.active {
    background-color: #eeeeee;
    color: #000;
}

.btn-right.active {
    background-color: #eeeeee;
    color: #000;
}

.btn-left:hover,
.btn-right:hover {
    background-color: #d0d0d0;
}


</style>
      