<template>
  <div class="container">
  <div class="messDiv  text-center">
    <CategorySelector
      :selectedCategory="selectedCategory"
      @category-selected="selectCategory"
      @reset-category="resetCategory"
    />
    <!-- Отображаем другие компоненты, если категория выбрана -->
    <div v-if="isCategorySelected">
      <br>
      <InterestSelector :category="selectedCategory" @interest-selected="addInterest" />
      <button @click="generateQuestions" class="btn mt-4">Готово!</button>
      <QuestionsDisplay v-if="questions.length > 0" :questions="questions" />
    </div>
  </div>
</div>
</template>
<script>
import CategorySelector from './adders/CategorySelector.vue'
import InterestSelector from './adders/InterestSelector.vue'
import QuestionsDisplay from './adders/QuestionsDisplay.vue'
export default {
  components: { CategorySelector, InterestSelector, QuestionsDisplay },
  data() {
    return {
      selectedCategory: null,
      selectedInterests: [],
      questions: [],
    };
  },
  computed: {
    isCategorySelected() {
      return !!this.selectedCategory;
    },
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category;
    },
    resetCategory() {
      this.selectedCategory = null;
      this.selectedInterests = [];
      this.questions = [];
    },
    addInterest(interest) {
      if (!this.selectedInterests.includes(interest)) {
        this.selectedInterests.push(interest);
      }
    },
    generateQuestions() {
      this.questions = this.selectedInterests.map((interest) => {
        switch (interest) {
          case 'Кино':
            return 'Какое ваше любимое кино?';
          case 'Музыка':
            return 'Какой жанр музыки вы любите и какой ваш любимый исполнитель?';
          case 'Сериалы':
            return 'Какой сериал вы смотрели недавно?';
          default:
            return `Расскажите о вашем интересе к "${interest}"`;
        }
      });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh; /* Полная высота экрана */
}
.messDiv{
  display: flex;
  flex-direction: column; /* Элементы друг под другом */
  justify-content: center; /* Вертикальное выравнивание */
  align-items: center; /* Горизонтальное выравнивание по центру */
  width: 100%;
  height: 100vh; /* Высота на весь экран */
  text-align: center;
  color: #fff;
         
}
.messDiv p {
  padding: 10px;
}
.messDiv h1,h2,h3,h4,h5{
  padding: 10px;
  margin: 0;
}
.messDiv button {
  color: #feb47b;
  width: 80%;
  background-color:aliceblue;
  margin-top: 35px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.messDiv input, select {
  color: #feb47b;
  background-color: aliceblue;
  border-color: aliceblue;
  text-align: center;
  margin-bottom: 15px; /* Отступ снизу от поля */
  width: 80%;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

</style>
  