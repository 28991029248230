<template>
     <main class="container cls">
      <div class="">
        <div class="p-5 rounded mt-3">
          <h3>Поделитесь с друзьями ссылкой на приложение</h3>
          <hr>
          <a class="btn btn-lg wbtn" role="button" @click="shareBot">Поделиться ссылкой</a>
        </div>
      </div>  
    </main>
  </template>
  
  <script>
  import { mapActions,mapState } from 'vuex';
  export default {
    name: 'repostApp',
    computed: {
      ...mapState(['currentComponent'])
    },
    methods: {
      ...mapActions(['changeComponent','updateMenuData']),
      shareBot() {
      const botUsername = 'Попробуй новое приложение для знакомств в Telegram'; // Ваш юзернейм бота
      const shareLink = `https://t.me/Peachstone_bot`; // Ссылка на бота
      const telegramShareUrl = `https://t.me/share/url?url=${shareLink}&text=${botUsername}`;

      window.location.href = telegramShareUrl; // Открывает Telegram с окном для отправки ссылки
    }
    },
    mounted() {
      this.updateMenuData(true)
    },
  };
  </script>
<style scoped>
  .messDiv{
  height: 100vh;
  background: linear-gradient(45deg, #ff7e5f, #feb47b);
  color:white;

}
.cls{
  color: #fff;
}
.nons{
  background-color: #feb47b00;
}
.wbtn{
  background-color: #fff;
  color: #ff7e5f;
}
</style>
 
  