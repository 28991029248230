import 'regenerator-runtime/runtime';
const BASE_URL = '/api/users';

// Функция для POST запросов
export async function postData(endpoint, data) {
      try {
        // Отправка запроса на сервер
        const response = await fetch(BASE_URL+endpoint, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json', // Указываем, что данные в формате JSON
              'Accept': 'application/json' 
          }
        });

        // Проверяем, успешный ли ответ
        if (!response.ok) {
            throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
        }

        // Парсинг ответа в формате JSON
        const responseData = await response.json();

        // Обработка полученных данных
        console.log('Ответ от сервера:', responseData);
        return responseData; // Возвращаем данные для дальнейшего использования
    } catch (error) {
        // Обработка ошибок
        console.error('Ошибка при запросе:', error);
    }
}


// Функция для POST запросов с файлами (например, изображениями)
export async function crAnketPhoto(endpoint, formData) {
      try {
        // Отправка запроса на сервер
        const response = await fetch(BASE_URL+endpoint, {
          method: 'POST',
        body: formData,
        headers: {
          // Заголовок Content-Type не указывается, fetch сам выставит multipart/form-data
          // 'Content-Type': 'multipart/form-data' — это НЕ НУЖНО и вызовет ошибку
        }
        });

        // Проверяем, успешный ли ответ
        if (!response.ok) {
            throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
        }

        // Парсинг ответа в формате JSON
        const responseData = await response.json();

        // Обработка полученных данных
        console.log('Ответ от сервера:', responseData);
        return responseData; // Возвращаем данные для дальнейшего использования
    } catch (error) {
        // Обработка ошибок
        console.error('Ошибка при запросе:', error);
    }
}

export async function fetchAnkets(endpoint, data) {
  try {
    // Отправка запроса на сервер
    const response = await fetch(BASE_URL+endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
          'Content-Type': 'application/json', 
          'Accept': 'application/json' 
      }
    });

    // Проверяем, успешный ли ответ
    if (!response.ok) {
        throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
    }

    // Парсинг ответа в формате JSON
    const responseData = await response.json();

    // Обработка полученных данных
    console.log('Ответ от сервера:', responseData);
    return responseData; // Возвращаем данные для дальнейшего использования
  } catch (error) {
    // Обработка ошибок
    console.error('Ошибка при запросе:', error);
  }
}
// Можно добавить другие функции для PUT, DELETE запросов и другие специфические