<template class="messDiv">
      <div class="messDiv">
        <h1 class="position-absolute start-50 translate-middle">
          Анкета готова!
        </h1>
          <p class="position-absolute start-50 translate-middle">Мы временно отключили возможность вносить изменения в профиль, но скоро это исправим</p>
          <button type="button" @click="loadCreatePerson" class="btn btn-lg btnBg position-absolute start-50 translate-middle">Начать</button>
      </div>
  </template>
  
  <script>
  import { mapActions,mapState } from 'vuex';
  import { postData } from '../utils/api';
  export default {
    data() {
    return {
      tg: window.Telegram.WebApp,
    };
  },
    computed: {
      ...mapState(['currentComponent'])
    },
    methods: {
      ...mapActions(['updateUserData', 'updateAnketData','changeComponent']),
      async loaderInfo() {
      try {
        const data = await postData('/', {
          initData:this.tg.initData,
          initDataUnsafe: this.tg.initDataUnsafe,
          platform: this.tg.platform
        });
        if (data.user) {
          data.user.token = data.token
          this.updateUserData({...data.user}); 
          this.updateAnketData(data.ankets);
        } 
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    },
      loadCreatePerson() {
        this.changeComponent('AnketsNew');
      }
    },
    mounted() {
    //window.Telegram.WebApp.disableVerticalSwipes() 
  
    //window.Telegram.WebApp.impactOccurred('medium')
    this.loaderInfo();
  }
  };
  </script>
  
  <style scoped>
.messDiv{
  width: 100vw;
  /* И по аналогии с высотой, если требуется */
  height: 100vh;
  background: linear-gradient(45deg, #ff7e5f, #feb47b);
  color:white;
}
.messDiv h1 {
  text-align: center;
  margin-top: 50%;
}
.messDiv p {
  text-align: center;
  margin-top: 120%;
}
.messDiv button {
  text-align: center;
  margin-top: 140%;
  color: #feb47b;
  width: 300px;
  background-color:aliceblue;
}
.cover{
  background-color: white;
  width: 300px;
  height: 400px;
}
.colorLi{
  color: #feb47b;
  
}
  </style>
  