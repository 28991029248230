<!-- LookingForSelector.vue -->
<template>
    <div
      class="selector d-flex align-items-center justify-content-between"
      v-touch:swipe.left="nextOption"
      v-touch:swipe.right="prevOption"
    >
      <!-- Левая стрелка -->
      <button @click="prevOption" class="arrow">
        <i class="bi bi-arrow-left-circle-fill"></i>
      </button>
  
      <!-- Контейнер для анимации варианта -->
      <div class="option-container">
        <span
          class="option"
          :class="animationClass"
          @animationend="resetAnimation"
        >
          {{ options[currentIndex] }}
        </span>
      </div>
  
      <!-- Правая стрелка -->
      <button @click="nextOption" class="arrow">
        <i class="bi bi-arrow-right-circle-fill"></i>
      </button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        options: ["Ищу девушек", "Ищу Мужчин"], // Варианты для выбора
        currentIndex: 0, // Индекс текущего выбранного варианта
        animationClass: "", // Класс анимации для плавного перехода
      };
    },
    methods: {
      nextOption() {
        this.startAnimation("left");
      },
      prevOption() {
        this.startAnimation("right");
      },
      startAnimation(direction) {
        // Запуск анимации, если direction == "left" -> анимация влево
        this.animationClass = direction === "left" ? "slide-out-left" : "slide-out-right";
        
        setTimeout(() => {
          // Изменение текущего индекса после окончания анимации выхода
          if (direction === "left") {
            this.currentIndex = (this.currentIndex + 1) % this.options.length;
            this.animationClass = "slide-in-right";
          } else {
            this.currentIndex = (this.currentIndex - 1 + this.options.length) % this.options.length;
            this.animationClass = "slide-in-left";
          }
          this.$emit("lookingForSelected", this.options[this.currentIndex]);
        }, 200); // Небольшая задержка перед сменой текста
      },
      resetAnimation() {
        // Сброс анимации после завершения
        this.animationClass = "";
      },
    },
  };
  </script>
  
  <style scoped>
    .selector {
        width: 80%;
        margin-top: 15px;
        height: 50px;
        border-radius: 20px;
        background-color: aliceblue;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        user-select: none; /* Отключает выделение текста */
    }
  
  .option-container {
    overflow: hidden;
    width: 150px;
    text-align: center;
    color: #9d9d9d;
  }
  
  .option {
    display: inline-block;
    transition: transform 0.3s ease;
  }
  
  .arrow {
    background: none;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #9d9d9d;
    cursor: pointer;
    outline: none;
  }
  
  /* Анимация сдвига и появления */
  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOutRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  @keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  /* Классы для анимации */
  .slide-out-left {
    animation: slideOutLeft 0.3s forwards;
  }
  
  .slide-in-right {
    animation: slideInRight 0.3s forwards;
  }
  
  .slide-out-right {
    animation: slideOutRight 0.3s forwards;
  }
  
  .slide-in-left {
    animation: slideInLeft 0.3s forwards;
  }
  </style>