<template>
  <body class="demo-1">
		<main>
      <div class="overflow-auto" style="max-height: 100%;max-width: 100%;padding-top: 50%;" v-if="loading">
        <div class="" >
            <div class="text-center">
                <div class="spinner-border text-light " role="status"></div>
                <p class="text-center text-light pt-4 mt-4">Загрузка анкет</p>
            </div>
        </div>
      </div>
			<div class="slideshow" v-else>
				<div class="slides">
					<div
            v-for="(profile, index) in anket"
            :key="profile._id"
            :class="['slide', { 'slide--current': index === 0 }]">
          <div
             class="slide__img"
              :style="{ backgroundImage: `url(/api/users/f1/${profile.avatar})` }"
          ></div>
          <h2 class="slide__title">{{ profile.name }}</h2>
						<p class="slide__desc">{{ profile.about }}<br>
              <span class="badge text-bg-light rounded-pill">Возраст: {{ calculateAge(profile.birthdate) }}</span>
            </p>
					</div>
				</div>
				<nav class="slidenav d-flex gap-5 justify-content-center bubs">
          <div class="d-flex gap-5 justify-content-center bubs ">
            <div class="navigBTNS">
              <button class="btn btn-light rounded-circle p-4 w-100 h-100 lh-1 slidenav__item--next animated-button d-flex justify-content-center align-items-center" @click="prevSlide(true)" :disabled="isDisabled" type="button">
                <i class="bi bi-heart-fill fs-1 pt-1"></i>
              </button>
            </div>  
            <div class="dust-container-dsl"></div>
            <div class="navigBTNS mt-4">
              <button type="button" class="btn btn-light rounded-circle position-relative p-4 w-100 h-100 lh-1 d-flex justify-content-center align-items-center" @click="nextPage">
                <i data-v-637c8da4="" class="bi bi-box2-heart-fill fs-1"></i>
                <span v-if="update" class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-2">
                  <span class="visually-hidden">unread messages</span>
                </span>
              </button>
            </div>
            <div class="navigBTNS">
              <button class="btn btn-light border-light rounded-circle p-4 w-100 h-100 lh-1 slidenav__item--prev reverse-animated-button d-flex justify-content-center align-items-center" @click="prevSlide(false)" :disabled="isDisabled" type="button">
                <i class="bi bi-x-lg fs-1 "></i>
              </button>
            </div>  
            <div class="dust-container-like"></div>
          </div>     
				</nav>
			</div>
		</main>
	</body>
</template>
<script>
import { initializeDemoNavigation } from '../assets/utils/demo'; // Импортируем функцию из demo.js
import { animateButton, animateReverseButton } from '../assets/utils/animbt.js'; // Импортируем функции из animbt.js
import { Slideshow } from '../assets/utils/demo1.js'; // Импортируем из demo1.js
import { mapActions, mapState } from 'vuex';
import { fetchAnkets } from '../utils/api';
export default {
  name: 'anketsNew',
  data() {
    return {
      loading: true,
      isDisabled: false,
      currentSlide:0,
      nAnkets:-1,
      update: false,
    };
  },
  computed: {
    ...mapState(['user','anket', 'actions','actMenu']),
  },
  methods: {
    ...mapActions(['updateAnketData','addAnketData', 'updateActionsData','updateUserData','updateMenuData','dellAnketData','dellActionsData', 'changeComponent']),
    triggerHapticFeedback(act) {
      // Проверяем наличие HapticFeedback в Telegram Web App API
      if (window.Telegram?.WebApp?.HapticFeedback) {
        if(act){
          window.Telegram.WebApp.HapticFeedback.impactOccurred('heavy');
        }else{
          window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
        }
        
      } else {
        console.log('Haptic feedback не поддерживается.');
      }
    },
    async getFetchAnkets(){
      const token = this.user.token
      let actions = null
      if(this.actions.length === 0){
        actions = false
      }else{
        actions = this.actions
      }
      const anketsUser = await fetchAnkets('/ankets',{token,actions,page:this.currentSlide})
      if(anketsUser.data == false){this.loading = true; this.updateMenuData(true)}else{this.loading = false;this.updateMenuData(false)} 
      this.currentSlide = this.currentSlide + anketsUser.data.length - 1;
      this.updateAnketData(anketsUser.data);
      this.updateUserData({ token: anketsUser.token });
      
    },
    async updateFetchAnkets(){
      const token = this.user.token
      let actions = null
      if(this.actions.length === 0){
        actions = false
      }else{
        actions = this.actions
      }
      const anketsUser = await fetchAnkets('/ankets',{token,actions,page:this.currentSlide})
      if(anketsUser.data.length === 0){
         //сообщение о том что анкеты закончились
       
      }else{
        this.addAnketData(anketsUser.data);
        this.updateUserData({ token: anketsUser.token });
      }
    },
    async actionsUser(action){
      const tid = this.anket[this.nAnkets].tid 
      this.updateActionsData({tid,action})
      //alert(`${this.anket.length} - ${this.nAnkets}`)
      if(this.nAnkets+1 === this.anket.length){
        this.changeComponent('noAncets');
         }
      if(this.anket.length < this.nAnkets+3){
        //Отправка оставшихся actions 
        await this.updateFetchAnkets()
        this.dellActionsData()
      }
      if(this.actions.length === 3){
        //Отправка запроса на сервер для получения новых анкет и передачи данных
        await this.updateFetchAnkets()
        //Очистка actions
        this.dellActionsData()
        //Удаление старых анкет из anket  в количестве 3-х штук
        //this.dellAnketData(3)
      }
    },
   async disableButtons(action) {
    // можно прописать код для отслеживания
      await this.actionsUser(action)
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 1450); // Восстановление активности через 2 секунды
  },
    calculateAge(birthdate) {
      const today = new Date();
      const birthDate = new Date(birthdate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    // Кнопочки
    prevSlide(act) {
      if (this.isDisabled) return; // Не выполнять, если кнопки отключены
      this.nAnkets = this.nAnkets+1
      this.disableButtons(act);
      this.triggerHapticFeedback(act);
    },
    nextPage(){
      this.changeComponent('matchAnkets');
      this.triggerHapticFeedback();
    }
    

    },
  mounted() {
    // Инициализируем демо-навигацию
    
    this.getFetchAnkets().then(()=>{
    initializeDemoNavigation();
    new Slideshow(document.querySelector('.slideshow'));
    animateButton();
    animateReverseButton();
    })
    // Инициализируем слайдшоу
    
    


  }
};
</script>
<style scoped>
/* Подключаем стили для компонента */
@import '../assets/css/base.css';
@import '../assets/css/stylebt.css';

</style>